<template>
  <section id="content">
    <base-card>
      <v-card-text>
        This is an example page. It’s different from a blog post because it will stay in one place and will show up in your site navigation (in most themes). Most people start with an About page that introduces them to potential site visitors. It might say something like this:
      </v-card-text>
      <blockquote class="blockquote pr-6 pr-md-0">
        Hi there! I’m a bike messenger by day, aspiring actor by night, and this is my website. I live in Los Angeles, have a great dog named Jack, and I like piña coladas. (And gettin’ caught in the rain.)
      </blockquote>
      <v-card-text>
        ...or something like this:
      </v-card-text>
      <blockquote class="blockquote pr-6 pr-md-0">
        The XYZ Doohickey Company was founded in 1971, and has been providing quality doohickeys to the public ever since. Located in Gotham City, XYZ employs over 2,000 people and does all kinds of awesome things for the Gotham community.
      </blockquote>
      <v-card-text>
        As a new WordPress user, you should go to your dashboard to delete this page and create new pages for your content. Have fun!
      </v-card-text>
    </base-card>
  </section>
</template>

<script>
  export default {
    name: 'SampleContents',
  }
</script>
